<template>
  <div ref="column" :class="'scrollColumn '+(left==1?'left':left==0?'right':'center')">
    <div
      v-for="(image, i) in images"
      :key="image.id+i+left"
      :class="{imageContainer:true, split: image.split}"
      @click="gotoProject()"
    >
      <ImageElement :img="image.image" />
      <!-- <div v-html="image.projectname" :style="textPosition(i)" class="projectTitle" /> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PerfectScrollbar from "perfect-scrollbar";
import ImageElement from "@/components/Image.vue";
function ease(t) {
  return (-1 / 2) * (Math.cos(Math.PI * t) - 1);
}
export default {
  data() {
    return {
      scrollHandler: null,
      perfectScroll: null,
      scrollTop: 0,
      targetScrollTop: 0,
      lastScrollTime: 0,
      scrollStart: 0,
      pScroll: false,
      delay: 100,
      speed: 1.5,
      translength: 0,
      autoScrolling: false,
      lastActualScroll: 0,
    };
  },
  props: ["images", "left"],
  components: { ImageElement },
  mounted() {
    this.perfectScroll = new PerfectScrollbar(this.$refs.column, {
      swipeEasing: false,
    });
    this.scrollTop = this.$refs.column.scrollTop;
    this.$refs.column.addEventListener(
      "ps-scroll-y",
      this.updateScroll.bind(this)
    );
    this.renderFrame = requestAnimationFrame(this.render);
    this.pScroll = this.$refs.column.scrollTop;
  },
  beforeUnmount() {
    cancelAnimationFrame(this.renderFrame);
  },
  computed: {
    ...mapGetters({
      home: "home/home",
    }),
    ...mapState("display", ["mouse", "touch", "mobile", "screen"]),
    height() {
      return this.screen.h;
    },
  },
  methods: {
    render() {
      if (
        this.autoScrolling &&
        new Date().getTime() - this.lastScrollTime < this.translength
      ) {
        var scrollAmount = ease(
          (new Date().getTime() - this.lastScrollTime) / this.translength
        );
        this.$refs.column.scrollTop =
          this.scrollStart +
          (this.scrollTarget - this.scrollStart) * scrollAmount;
        this.pScroll = this.$refs.column.scrollTop;
      } else if (
        new Date().getTime() - this.lastScrollTime > this.translength &&
        this.autoScrolling
      ) {
        this.$refs.column.scrollTop = this.scrollTarget;
        this.pScroll = this.$refs.column.scrollTop;
        // this.autoScrolling = false;
      }
      this.renderFrame = requestAnimationFrame(this.render);
    },
    updateScroll(e) {
      this.scrollTop = e.srcElement.scrollTop;
      if (this.autoScrolling) {
        e.srcElement.scrollTop = this.pScroll;
        if (new Date().getTime() - this.lastActualScroll > 40) {
          this.autoScrolling = false;
        } else {
          this.lastActualScroll = new Date().getTime();
        }
      } else if (this.pScroll != e.srcElement.scrollTop) {
        var direction = this.scrollTop - this.pScroll;
        direction = Math.abs(direction) / direction;

        e.srcElement.scrollTop = this.pScroll;
        this.scrollTarget =
          Math.max(
            0,
            Math.min(
              direction + Math.round(this.scrollTop / this.height),
              this.images.length
            )
          ) * this.height;

        this.scrollStart = this.pScroll;
        this.lastScrollTime = new Date().getTime();

        this.lastActualScroll = new Date().getTime();
        this.translength =
          Math.abs(this.scrollTarget - this.scrollTop) / this.speed;
        this.autoScrolling = true;
      }
    },
    gotoProject() {
      this.$router.push({ name: "Menu" });
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollColumn {
  -webkit-overflow-scrolling: auto;
}
.imageContainer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    pointer-events: none;
  }
  &.split img {
    width: 200%;
  }
  .projectTitle {
    position: absolute;
    transform: translateY(-100%);
    text-align: center;
    color: white;
    z-index: 10;
    white-space: nowrap;
    font-size: 50px;
    font-weight: bold;
    pointer-events: none;
    opacity: 0;
  }
  &:hover .projectTitle {
    opacity: 1;
  }
}

.right .split img {
  transform: translateX(-50%);
}

@media screen and (max-width: $breakPoint) {
  .imageContainer .projectTitle {
    opacity: 0 !important;
  }
  .imageContainer.hideOnMobile {
    display: none;
  }
}
</style>