<template>
  <div class="homeContainer" ref="homeContainer">
    <template v-if="!mobile">
      <scroll-column :images="leftColumn" :left="1" key="left" />
      <scroll-column :images="rightColumn" :left="0" key="right" />
    </template>
    <template v-else>
      <scroll-column :images="combined" :left="2" key="center" />
    </template>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapGetters, mapState } from "vuex";
import ScrollColumn from "@/components/ScrollColumn";
export default {
  name: "Home",
  components: { ScrollColumn },
  mounted() {
    console.log(this.home);
  },
  computed: {
    ...mapGetters({
      home: "home/home",
    }),
    ...mapState("display", ["mobile"]),
    leftColumn() {
      var images = [];
      this.home.images.forEach((img) => {
        if (img.split) {
          images.push({
            image: img.image,
            split: true,
            id: img.id,
          });
        } else {
          images.push({
            image: img.left,
            split: false,
            id: img.id,
          });
        }
      });
      return images;
    },
    rightColumn() {
      var images = [];
      this.home.images.forEach((img) => {
        if (img.split) {
          images.push({
            image: img.image,
            split: true,
            id: img.id,
          });
        } else {
          images.push({
            image: img.right,
            split: false,
            id: img.id,
          });
        }
      });
      return images;
    },
    combined() {
      var images = [];
      this.home.images.forEach((img) => {
        if (img.split) {
          images.push({
            image: img.image,
            split: false,
            id: img.id,
          });
        } else {
          images.push({
            image: img.left,
            split: false,
            id: img.id,
          });
          images.push({
            image: img.right,
            split: false,
            id: img.id,
          });
        }
      });
      return images;
    },
  },
  methods: {},
  watch: {
    mobile: {
      handler() {
        this.$nextTick(() => {
          this.lazyload.update();
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.homeContainer {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  & > div {
    flex-basis: 50%;
  }
}

#leftColumn,
#rightColumn {
  flex-basis: 50%;
  overflow: hidden;
  height: 100%;
}

#mainColumn {
  height: 100%;
}

@media screen and (max-width: $breakPoint) {
  .homeContainer {
    display: block;
    height: 100%;
    & > div {
      flex-basis: 100%;
      height: 100%;
    }
  }
}
</style>