<template>
  <div class="about">
    <div v-html="general.aboutText"></div>
  </div>
</template>
<script>
export default {
  props: ["general"],
};
</script>
<style lang="scss" scoped>
.about {
  padding: calc(2em + $paddingMain) $paddingMain $paddingMain $paddingMain;
  font-size: $big;
  line-height: 0.9em;
}
@media screen and (max-width: $breakPoint) {
  .about {
    padding: calc($paddingAfterTitle) $paddingTop $paddingTop $paddingTop;
  }
}
</style>